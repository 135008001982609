import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Form from "../../components/Form"


import roofing2 from "../../images/roof.jpg"
import logo from "../../images/navbar-logo.svg"

const ContactPage = () => {
  return (
    <Layout>
      <div className="services-page">
        <section>
          <div className="services-section-top">
            <h1>CONTACT US</h1>
          </div>
        </section>
        <section>
          <div className="content-section">
            <div className="content-section-left">
              <img src={roofing2} alt="roofing" />
            </div>
            <div className="content-section-right">
              <h2>Quality Service, Fair Price</h2>
              <p>
                We can enhance the beauty, value and durability of any property
                with services that include Kitchen Remodeling, Bathroom
                Renovation, Roofing, Basement Renovation and Home Painting. We
                are one of the leading Maryland home improvement contractors
                with a great reputation both in the community and in the home
                renovation industry.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="form-section">
            <div className="form-section-bottom">
              <div className="form-section-bottom-left">
                <img src={logo} alt="company logo" />
                <h3>
                  Get started with us by filling out the form to request your{" "}
                  <span style={{ color: `#cb3100` }}>FREE ESTIMATE</span>, or
                  give us a call at{" "}
                  <span style={{ color: `#cb3100` }}>(443) 623-2111</span> and
                  we'll be in touch!
                </h3>
              </div>
              <div className="form-section-bottom-right">
                <Form />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ContactPage
