import React from 'react'
import './Form.css'

const Form = () => (
  <div className='form-container'>
  <h2>Free Estimates</h2>
  <p>It's our pleasure to get started on your next renovation project</p>
  <form
  name="contact"
  method="post"
  data-netlify="true"
  data-netlify-honeypot="bot-field"
  action='/contact/success'
  >
  <input type="hidden" name="form-name" value="contact" />
  <input
    name='name'
    placeholder='Name'
    maxlength="40"
    required
  />
  <input
    name='email'
    placeholder='Email'
    maxlength="75"
    required
  />
  <input
    name='tel'
    type="tel"
    placeholder='Telephone'
    maxlength="10"
    required
  />
  <textarea
    name='message'
    placeholder='Brief project description'
  />
  <button type='submit'><p>SEND</p></button>
</form>
  </div>
)

export default Form
